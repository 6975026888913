import * as React from "react"
import styled from "styled-components"

import Facility from "./Facility"
import Section from "../Section"
import SectionHeading from "../SectionHeading"

import iconTemperature from "../../images/welcome/icon-temperature.svg"
import iconArrowUp from "../../images/welcome/icon-arrow-up.svg"
import iconDistance from "../../images/welcome/icon-distance.svg"
import icon50m from "../../images/welcome/icon-50m.svg"
import iconWedge from "../../images/welcome/icon-wedge.svg"
import iconDoubleArrow from "../../images/welcome/icon-double-arrow-right.svg"
import iconGym from "../../images/welcome/icon-weights.svg"
import iconMuscle from "../../images/welcome/icon-muscle.svg"

const Container = styled.div`
  background-color: #f2f2f2;
  padding: 20px 0 50px 0;

  .facility-grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    column-gap: 100px;
    row-gap: 50px;
    grid-template-areas:
      "temperature"
      "distance"
      "wedge"
      "gym";
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .facility-grid {
      margin-top: 50px;
      row-gap: 0px;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto auto auto;
      grid-template-areas:
        "temperature ."
        ". distance"
        "wedge ."
        ". gym";
    }
  }
`

type FacilitiesObject = {
  iconMain: string
  iconSecondary: string
  title: string
  description: string
  gridArea: "temperature" | "distance" | "wedge" | "gym"
}[]

const OurFacilities = () => {
  const facilities: FacilitiesObject = [
    {
      iconMain: iconTemperature,
      iconSecondary: iconArrowUp,
      title: "Four powerful heaters",
      description:
        "Our heaters are running around the clock to ensure that we can keep on swimming, " +
        "even during the cold winter months.",
      gridArea: "temperature",
    },
    {
      iconMain: iconDistance,
      iconSecondary: icon50m,
      title: "Olympic distance pool",
      description:
        "A 50-metre long pool allows our swimmers to quickly build up endurance during training " +
        "sessions as well as focus on their stroke and rythm through uninterrupted swimming.",
      gridArea: "distance",
    },
    {
      iconMain: iconWedge,
      iconSecondary: iconDoubleArrow,
      title: "Wedged starting blocks",
      description:
        "A wedge at the back of a starting block gives you that extra edge you need to pull off a " +
        "powerful start.",
      gridArea: "wedge",
    },
    {
      iconMain: iconGym,
      iconSecondary: iconMuscle,
      title: "Land training equipment",
      description:
        "With our in-house biokeneticist and gym equipment, our swimmers are never far from proper " +
        "physical training outside of the water",
      gridArea: "gym",
    },
  ]
  return (
    <Container id="facilities">
      <Section>
        <SectionHeading heading="Our Facilities." align="center" />
        <div className="facility-grid">
          {facilities.map((facility, index) => (
            <Facility
              iconMain={facility.iconMain}
              iconSecondary={facility.iconSecondary}
              title={facility.title}
              description={facility.description}
              gridArea={facility.gridArea}
              key={index + facility.gridArea}
            />
          ))}
        </div>
      </Section>
    </Container>
  )
}

export default OurFacilities
