import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../Section"
import Slice from "../Slice"
import IconBlurButton from "./IconBlurButton"

import iconLocation from "../../images/welcome/icon-location.svg"
import iconSwim from "../../images/welcome/icon-swim.svg"
import SectionHeading from "../SectionHeading"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  div.text {
    h1 {
      font-size: 48px;

      .dot {
        color: #0154d1;
      }
    }
  }

  div.image-section {
    position: relative;

    .icon-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      margin: 20px 0 20px 0;
    }

    .welcome-image {
      border-radius: 10px;
      max-width: 550px;
      z-index: 0;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    padding: 100px 0 50px 0;

    div.text {
      max-width: 350px;
    }

    .icon-buttons {
      border: none;
      position: absolute;
      top: 25px;
      left: -20px;
      z-index: 1;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    div.text {
      max-width: 550px;
    }
  }
`

const Welcome = () => {
  return (
    <>
      <Section>
        <Container id="welcome">
          <div className="text">
            <SectionHeading heading="Welcome to Players." />
            <p>
              We are a swimming club located in Menlo Park, Pretoria. Our
              swimming pool at Hoërskool Menlopark offers well-managed
              facilities that keep us swimming all-year round.
            </p>
            <p>
              Our swimmers form a tight-knit family, and we pride ourselves on
              maintaining a supportive culture in all aspects, both inside and
              outside of the pool.
            </p>
          </div>
          <div className="image-section">
            <StaticImage
              src="../../images/welcome/img-welcome.png"
              alt="Swimmers hugging"
              className="welcome-image"
              placeholder="blurred"
              loading="lazy"
            ></StaticImage>
            <div className="icon-buttons">
              <IconBlurButton
                text="Our services"
                iconPath={iconSwim}
                link="/services"
                splashPos={{ top: 0, left: 10, rotation: -270 }}
              />
              <IconBlurButton
                text="Find us"
                iconPath={iconLocation}
                link="/#location"
                splashPos={{ top: -5, left: -8, rotation: 0 }}
              />
            </div>
          </div>
        </Container>
      </Section>
      <Slice color="#f2f2f2" />
    </>
  )
}

export default Welcome
