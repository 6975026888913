import * as React from "react"
import styled from "styled-components"
import { contactLinks } from "../../utils/constants"
import IconArrow from "../icons/IconArrow"

import SectionHeading from "../SectionHeading"
import Slice from "../Slice"
import Spacer from "../Spacer"
import imgMapSatellite from "../../images/welcome/img-map-satellite.jpg"

const MainContainer = styled.div`
  position: relative;

  .content-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    .content-container {
      flex-direction: row;
      gap: 100px;
    }
  }
`

const MapContainer = styled.iframe`
  min-width: 100%;
  min-height: 200px;
  margin: 0;
  border: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 40vw;
    min-height: 550px;
  }
`

const ImageContainer = styled.div`
  min-width: 100%;
  min-height: 200px;

  img {
    margin: 0;
    object-fit: cover;
    min-height: 200px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 30vw;
    max-width: 40vw;
    min-height: 550px;
    overflow: hidden;

    img {
      min-height: 550px;
      transition: transform 0.2s;

      :hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }
`

const Content = styled.div`
  margin: 50px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1,
  p {
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.palette.scienceBlue};
    text-decoration: none;
    font-size: 12px;
    font-family: "PoppinsSemiBold", sans-serif;
    display: flex;
    gap: 0px;
    align-items: center;
    transition: gap 0.2s;

    :hover {
      gap: 5px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 0 50px 0 0;
  }
`

const WhereAreWe = () => {
  return (
    <MainContainer id="location">
      <Slice
        color="#f2f2f2"
        flipVertical
        flipHorizontal
        style={{ position: "absolute", zIndex: 1 }}
      />
      <div className="content-container">
        {/* <MapContainer src="https://snazzymaps.com/embed/387550" /> */}
        <a href={contactLinks.map} target="_blank">
          <ImageContainer>
            <img src={imgMapSatellite} />
          </ImageContainer>
        </a>
        <Content>
          <SectionHeading heading="Where are we?" />
          <p>
            We are located in Menlo Park, Pretoria and in association with
            Menlopark High School, making use of their swimming pool.
            <br />
            Please see the map or contact us for further details.
          </p>
          <a className="learn-more" href={contactLinks.map} target="_blank">
            Get directions <IconArrow color="#0154d1" />
          </a>
        </Content>
      </div>
      <div style={{ position: "absolute", bottom: "0", left: "0" }}>
        <Slice color="#222222" />
        <Spacer height={15} color={"#222"} />
      </div>
    </MainContainer>
  )
}

export default WhereAreWe
