import * as React from "react"
import styled from "styled-components"

import Section from "../Section"

import Slice from "../Slice"
import { StaticImage } from "gatsby-plugin-image"

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.mineShaft};
  overflow: hidden;
  width: 100%;
  z-index: -1;
  position: relative;

  .content {
    position: relative;
    height: 350px;

    .title-container {
      display: flex;
      align-items: center;
      justify-content: left;
      height: 200px;
      max-width: 450px;
      z-index: 9999;
      position: relative;

      h1 {
        color: ${({ theme }) => theme.palette.white};
        font-size: 52px;
        line-height: 45px;
        margin: 0;
        position: absolute;
        bottom: 0;
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    .content {
      height: 600px;

      .title-container {
        height: 300px;

        h1 {
          font-size: 82px;
          line-height: 75px;
          margin: 0;
          span {
            color: ${({ theme }) => theme.palette.mineShaft};
          }
        }
      }
    }
  }
`

const BackgroundImage = styled.div`
  img {
    filter: contrast(110%) saturate(90%) hue-rotate(-10deg) brightness(80%);
  }
  min-height: 350px;
  min-width: 100%;
  margin: 0;
  object-fit: cover;
  position: absolute;
  z-index: -1000000;

  .gatsby-image-wrapper {
    min-height: 350px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-height: 600px;

    .gatsby-image-wrapper {
      min-height: 600px;
    }
    img {
      filter: contrast(110%) saturate(90%) hue-rotate(-10deg) brightness(110%);
    }
  }
`

const Header = () => {
  return (
    <Container>
      <div className="content">
        <BackgroundImage>
          <StaticImage
            src="../../images/welcome/img-hero.png"
            alt="Players Swim"
            placeholder="dominantColor"
            loading="eager"
            layout="fullWidth"
            quality={100}
          />
        </BackgroundImage>
        <Section>
          <div className="title-container">
            <h1>
              Become part of the <span>family.</span>
            </h1>
          </div>
        </Section>
        <Slice color="#ffffff" style={{ position: "absolute", bottom: "0" }} />
      </div>
    </Container>
  )
}

export default Header
