import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Welcome from "../components/welcome/Welcome"
import OurFacilities from "../components/welcome/OurFacilities"
import WhereAreWe from "../components/welcome/WhereAreWe"
import Header from "../components/welcome/Header"
import HeroInfo from "../components/welcome/HeroInfo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <HeroInfo />
    <Header />
    <Welcome />
    <OurFacilities />
    <WhereAreWe />
  </Layout>
)

export default IndexPage
