import * as React from "react"
import styled from "styled-components"

import Icon from "../Icon"
import iconFamily from "../../images/welcome/icon-family.svg"
import iconLifebuoy from "../../images/welcome/icon-lifebuoy.svg"
import iconTrophy from "../../images/welcome/icon-trophy.svg"
import IconArrow from "../icons/IconArrow"
import { Link } from "gatsby"

const Container = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;

    .content-container {
      background-color: ${({ theme }) => theme.palette.white};
      border-radius: 10px;
      min-width: 750px;
      width: 750px;
      padding: 10px;
      box-shadow: 0px 2px 5px ${({ theme }) => `${theme.palette.black}20`};
      position: absolute;
      bottom: -50px;
      z-index: 9;
    }

    .group-container {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .single-info-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        border-radius: 10px;
        transition: 0.2s;
        flex: 1 1 0px; //flex-grow flex-shrink flex-basis

        h1 {
          font-size: 24px;
        }

        h1,
        p {
          margin: 0;
        }

        a {
          color: ${({ theme }) => theme.palette.scienceBlue};
          text-decoration: none;
          font-size: 12px;
          font-family: "PoppinsSemiBold", sans-serif;
          color: ${({ theme }) => theme.palette.scienceBlue};
          display: flex;
          gap: 0px;
          align-items: center;
          transition: gap 0.2s;

          :hover {
            gap: 5px;
          }
        }
      }
    }
  }
`

const HeroInfo = (): JSX.Element => {
  const infoArray = [
    {
      icon: {
        src: iconFamily,
        splashPos: { top: 0, left: 5, rotation: 30 },
      },
      title: "All ages",
      description:
        "We offer a wide variety of programmes to accompany every level of swimming.",
      link: "/services",
    },
    {
      icon: {
        src: iconLifebuoy,
        splashPos: { top: 5, left: -10, rotation: 0 },
      },
      title: "Water safety",
      description:
        "Our coaches are equipped with proven techniques to ensure your little ones" +
        "are safe in and around water.",
      link: "/services",
    },
    {
      icon: {
        src: iconTrophy,
        splashPos: { top: -5, left: 5, rotation: 120 },
      },
      title: "Competitive swimming",
      description:
        "Our elite squads take part in competitions all around the country.",
      link: "/services",
    },
  ]
  return (
    <Container>
      <div className="content-container">
        <div className="group-container">
          {infoArray.map((info, index) => (
            <div className="single-info-container" key={index}>
              <Icon src={info.icon.src} splashPos={info.icon.splashPos} />
              <h1>{info.title}</h1>
              <p className="description">{info.description}</p>
              <Link className="learn-more" to={info.link}>
                Learn more <IconArrow color="#0154d1" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default HeroInfo
