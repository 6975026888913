import * as React from "react"
import styled from "styled-components"
import splash from "../images/splash.svg"

type Props = {
  src: string
  size?: number
  splashPos?: {
    top: number
    left: number
    rotation: number
  }
}

const Container = styled.div<{
  splashLeft: string
  splashTop: string
  splashRotation: string
}>`
  position: relative;

  .splash {
    position: absolute;
    z-index: 1;
    left: ${({ splashLeft }) => splashLeft};
    top: ${({ splashTop }) => splashTop};
    transform: rotate(${({ splashRotation }) => splashRotation});
  }

  img {
    position: relative;
    z-index: 2;
    margin: 0;
    display: block;
  }
`

const Icon: React.FC<Props> = ({ src, size = 32, splashPos }) => {
  const top = splashPos?.top + "px"
  const left = splashPos?.left + "px"
  const rotation = splashPos?.rotation + "deg"

  return (
    <Container splashLeft={left} splashTop={top} splashRotation={rotation}>
      <img src={src} width={size} />
      {splashPos ? <img className="splash" src={splash} /> : null}
    </Container>
  )
}

export default Icon
