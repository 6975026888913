import * as React from "react"
import styled from "styled-components"

const Container = styled.div<{ color: string; height: number }>`
  background-color: ${({ color }) => color};
  height: ${({ height }) => height + "px"};
  width: 100vw;
`
type Props = {
  color?: string
  height?: number
}

const Spacer: React.FC<Props> = ({ color = "transparent", height = 50 }) => {
  return <Container color={color} height={height} />
}

export default Spacer
