import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import * as React from "react"
import styled from "styled-components"

import Icon from "../Icon"
import IconArrow from "../icons/IconArrow"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  padding: 10px;

  background-color: ${({ theme }) => `${theme.palette.mineShaft}10`};
  transition: 0.2s;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => `${theme.palette.white}90`};

    .link-text {
      gap: 5px;
    }
  }

  img,
  p {
    margin: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-color: ${({ theme }) => `${theme.palette.white}60`};
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: -4px 4px 10px ${({ theme }) => `${theme.palette.black}10`};
    width: 135px;
    height: 85px;
  }
`

const LinkText = styled.div`
  display: flex;
  gap: 0px;
  text-decoration: none;
  color: #222;
  align-items: center;
  transition: 0.2s;
  margin: 0;
`

const StyledAnchorLink = styled(AnchorLink)`
  text-decoration: none;
`

type Props = {
  iconPath: string
  text: string
  link: string
  splashPos?: {
    top: number
    left: number
    rotation: number
  }
}

const IconBlurButton = ({
  iconPath,
  text,
  link,
  splashPos,
}: Props): JSX.Element => {
  return (
    <StyledAnchorLink to={link} title={text}>
      <Container>
        <Icon src={iconPath} size={32} splashPos={splashPos} />
        <LinkText className="link-text">
          <p>{text}</p> <IconArrow />
        </LinkText>
      </Container>
    </StyledAnchorLink>
  )
}

export default IconBlurButton
