import * as React from "react"
import styled from "styled-components"
import Icon from "../Icon"

const Container = styled.div<{ gridArea: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  grid-area: ${({ gridArea }) => gridArea};
  z-index: 10;

  .icons-container {
    position: relative;
  }

  .details {
    h1 {
      font-size: 24px;
      font-family: "PoppinsBold", sans-serif;
      color: ${({ theme }) => theme.palette.scienceBlue};
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    text-align: left;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: -18px 0 -18px 0;
  }
`

const IconContainer = styled.div`
  background-color: #0154d1;
  background-image: linear-gradient(to right, #0154d1, #0166fe);

  -webkit-box-shadow: inset 0 4px 10px #00000025;
  -moz-box-shadow: inset 0 4px 10px #00000025;
  box-shadow: inset 0 4px 10px #00000025;

  border-radius: 100px;
  width: 140px;
  height: 140px;

  display: flex;
  align-items: center;
  justify-content: center;
`

const SecondaryIconContainer = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -10px;
  left: -10px;

  border-radius: 100px;
  background-color: #ffffff60;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: -4px 4px 10px #00000010;
`

type Props = {
  iconMain: string
  iconSecondary: string
  title: string
  description: string
  gridArea: "temperature" | "distance" | "wedge" | "gym"
}

const Facility = ({
  iconMain,
  iconSecondary,
  title,
  description,
  gridArea,
}: Props): JSX.Element => {
  return (
    <Container gridArea={gridArea}>
      <div className="icons-container">
        <IconContainer>
          <Icon src={iconMain} size={140} />
        </IconContainer>
        <SecondaryIconContainer>
          <Icon src={iconSecondary} size={70} />
        </SecondaryIconContainer>
      </div>
      <div className="details">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </Container>
  )
}

export default Facility
