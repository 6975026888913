import * as React from "react"
import styled from "styled-components"

const Heading = styled.h1<{ align: string }>`
  font-size: 48px;
  text-align: ${({ align }) => align};

  .blue {
    color: ${({ theme }) => theme.palette.scienceBlue};
  }
`

const SectionHeading = ({ heading, align = "left" }) => {
  const title = heading.slice(0, heading.length - 1)
  const finalChar = heading.slice(-1)
  return (
    <Heading align={align}>
      {title}
      <span className="blue">{finalChar}</span>
    </Heading>
  )
}

export default SectionHeading
